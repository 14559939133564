import React, { useState } from "react";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import logo from "./logo.png";
import registerVector from "./register_vector.jpg";
import logoAnde from "./ande_logo.png";
import logoAnii from "./anii_logo.png";
import logoThales from "./thaleslab_logo.png";
import loadingImg from "./loading.gif";
import googlePlayLogo from "./google-play.png";
import appStoreLogo from "./app-store.png";
import downloadInstructions from "./download-pdf-40x40.png";
import { CarFrontFill, PersonFill } from "react-bootstrap-icons";
import ReactPlayer from "react-player";
import previewVideo from "./preview.png";
import "./App.css";

function App() {
  const [form, setForm] = useState({ role: 2 });
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState(1);
  const BASE_URL = "https://app.yaquevoy.com/api";

  const options = [
    { label: "Artigas", value: 1 },
    { label: "Canelones", value: 2 },
    { label: "Cerro Largo", value: 3 },
    { label: "Colonia", value: 4 },
    { label: "Durazno", value: 5 },
    { label: "Flores", value: 6 },
    { label: "Florida", value: 7 },
    { label: "Lavalleja", value: 8 },
    { label: "Maldonado", value: 9 },
    { label: "Montevideo", value: 10 },
    { label: "Paysandú", value: 11 },
    { label: "Río Negro", value: 12 },
    { label: "Rivera", value: 13 },
    { label: "Rocha", value: 14 },
    { label: "Salto", value: 15 },
    { label: "San José", value: 16 },
    { label: "Soriano", value: 17 },
    { label: "Tacuarembó", value: 18 },
    { label: "Treinta y Tres", value: 19 },
  ];

  const roles = [
    { name: "Remitente", value: 1 },
    { name: "Conductor", value: 2 },
  ];

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    //console.log("findFormErrors form", form);

    const {
      role,
      name,
      phone,
      document,
      email,
      password,
      passwordconfirm,
      frequently_cities,
      terms_accepted,
    } = form;

    const newErrors = {};
    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );
    const validPhone = new RegExp("^[0][0-9]{8}$");

    if (!name || name == undefined || name.trim() === "")
      newErrors.name = "Nombre es requerido";
    else if (name.length > 255) newErrors.name = "Nombre es muy largo";

    if (!phone || phone == undefined || phone.trim() === "")
      newErrors.phone = "Teléfono celular es requerido";
    else if (!validPhone.test(phone))
      newErrors.phone =
        "Teléfono celular no tiene el formato esperado 09NNNNNNN";

    if (!document || document == undefined || document.trim() === "") {
      newErrors.document = "Documento es requerido";
    } else if (document.length < 7) {
      newErrors.document = "Documento debe ser más largo";
    } else {
      let ci = document.replace(/\D/g, "");
      let dig = ci[ci.length - 1];
      ci = ci.replace(/[0-9]$/, "");

      if (dig != validation_digit(ci))
        newErrors.document = "El documento es incorrecto";
    }

    if (!email || email == undefined || email.trim() === "")
      newErrors.email = "Correo electrónico es requerido";
    else if (!validEmail.test(email))
      newErrors.email = "Correo electrónico no es válido";

    if (!password || password == undefined || password.trim() === "")
      newErrors.password = "Clave es requerido";
    else if (password.length < 8)
      newErrors.password = "Clave debe tener 8 caracteres o más.";
    else if (password != passwordconfirm)
      newErrors.passwordconfirm = "La clave no coincide";
    else if (password.length > 255) newErrors.password = "Clave es muy largo";

    if (
      !passwordconfirm ||
      passwordconfirm == undefined ||
      passwordconfirm.trim() === ""
    )
      newErrors.passwordconfirm = "Confirmación de clave es requerido";
    else if (passwordconfirm.length < 8)
      newErrors.passwordconfirm = "Clave debe tener 8 caracteres o más.";
    else if (password != passwordconfirm)
      newErrors.passwordconfirm = "La clave no coincide";
    else if (passwordconfirm.length > 255)
      newErrors.passwordconfirm = "Confirmación de clave es muy largo";

    if (!terms_accepted || terms_accepted == undefined)
      newErrors.terms_accepted = "Debes aceptar los términos y condiciones";

    //console.log("newErrors", newErrors);

    return newErrors;
  };

  const CleanFields = () => {
    setForm({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      setLoading(true);
      // No errors! Put any logic here for the form submission!
      //form.role = 2; // add role

      //let json = await signUp(form);

      fetch(`${BASE_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then((response) => {
          // console.log("response", response);
          // console.log("response != 200", response != 200);
          setLoading(false);
          // if (response != 200) {
          //   alert(
          //     "Ops! Ocurrió un error, intenta más tarde o comunícate con nosotros"
          //   );
          // }
          return response.json();
        })
        .then((data) => {
          if (data.access_token) {
            setLoading(false);
            setStatus("OK");
            CleanFields();
            //alert("Gracias por registrarte!");
          }
        })
        .catch((err) => {
          // console.log("======failure=======");
          // console.log(err);
          setLoading(false);
          setStatus("KO");
          // alert(
          //   "Ops! Ocurrió un error, intenta más tarde o comunícate con nosotros"
          // );
        });
    }
  };

  const handleChange = (selectedOptions) => {
    setField(
      "frequently_cities",
      selectedOptions.map((option) => option.value)
    );
  };

  const handleCheckboxChange = () => {
    setField("terms_accepted", !form.terms_accepted);
  };

  const validation_digit = (ci) => {
    let a = 0;
    let i = 0;
    if (ci.length <= 6) {
      for (i = ci.length; i < 7; i++) {
        ci = "0" + ci;
      }
    }
    for (i = 0; i < 7; i++) {
      a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
    }
    if (a % 10 === 0) {
      return 0;
    } else {
      return 10 - (a % 10);
    }
  };

  return (
    <div className="App">
      <div className="page-holder align-items-center py-4 bg-gray-100 vh-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="show col-lg-6 px-lg-4">
              <div className="card">
                <div
                  className="card-header px-lg-5"
                  style={{ backgroundColor: "#4650dd", textAlign: "center" }}
                >
                  <div className="card-heading text-primary">
                    <img src={logo} className="YaQueVoy-logo" alt="logo" />
                  </div>
                </div>
                <div className="card-body p-lg-5">
                  <h3 className="mb-4">Me quiero registrar como...</h3>
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    className="needs-validation"
                  >
                    <Form.Group className="form-floating mb-3">
                      <ButtonGroup>
                        {roles.map((role, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`role-${idx}`}
                            type="radio"
                            variant={
                              idx % 2 ? "outline-danger" : "outline-success"
                            }
                            name="role"
                            value={role.value}
                            checked={form.role == role.value}
                            onChange={(e) =>
                              setField("role", e.currentTarget.value)
                            }
                          >
                            {idx % 2 ? <CarFrontFill /> : <PersonFill />}
                            <span> </span>
                            {role.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </Form.Group>
                    {form.role == 2 ? (
                      <p className="text-muted text-sm mb-5">
                        Ingresa tus datos de contacto y tus departamentos
                        frecuentes.
                        <br className="d-none d-lg-inline" /> Ten presente que
                        si viajas a salto agregar también las ciudades del
                        trayecto ya sea Flores, Rio Negro, Paysandú etc.
                      </p>
                    ) : (
                      <p className="text-muted text-sm mb-5">
                        Ingresa tus datos de contacto.
                      </p>
                    )}
                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="name"
                        placeholder="ingresa tu nombre completo"
                        required
                        onChange={(e) => setField("name", e.target.value)}
                        value={form.name || ""}
                        isInvalid={!!errors.name}
                      />
                      <Form.Label>Nombre y apellido</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="phone"
                        placeholder="ingresa tu celular"
                        maxLength={10}
                        required
                        onChange={(e) => setField("phone", e.target.value)}
                        value={form.phone || ""}
                        isInvalid={!!errors.phone}
                      />
                      <Form.Label>Teléfono celular</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="text"
                        id="document"
                        placeholder="ingresa tu documento"
                        required
                        onChange={(e) => setField("document", e.target.value)}
                        value={form.document || ""}
                        isInvalid={!!errors.document}
                      />
                      <Form.Label>Documento</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.document}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="email"
                        id="email"
                        placeholder="ingresa tu correo electrónico"
                        required
                        onChange={(e) => setField("email", e.target.value)}
                        value={form.email || ""}
                        isInvalid={!!errors.email}
                      />
                      <Form.Label>Correo electrónico</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="password"
                        id="password"
                        required
                        onChange={(e) => setField("password", e.target.value)}
                        value={form.password || ""}
                        isInvalid={!!errors.password}
                      />
                      <Form.Label>Clave</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-floating mb-3">
                      <Form.Control
                        type="password"
                        id="passwordconfirm"
                        required
                        onChange={(e) =>
                          setField("passwordconfirm", e.target.value)
                        }
                        value={form.passwordconfirm || ""}
                        isInvalid={!!errors.passwordconfirm}
                      />
                      <Form.Label>Confirma clave</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.passwordconfirm}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {form.role == 2 && (
                      <Form.Group className="form-floating mb-3">
                        <Select
                          options={options}
                          isMulti
                          placeholder="Selecciona tus departamentos frecuentes"
                          onChange={handleChange}
                          // value={form.frequently_cities || []}
                          // isInvalid={!!errors.frequently_cities}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="form-check mb-3">
                      <FormCheck>
                        <FormCheck.Input
                          type="checkbox"
                          name="terms_accepted"
                          id="terms_accepted"
                          onChange={handleCheckboxChange}
                          value={form.terms_accepted || ""}
                          isInvalid={!!errors.terms_accepted}
                        />
                        <Form.Label
                          className="form-check-label"
                          htmlFor="terms_accepted"
                        >
                          Acepto los{" "}
                          <a
                            href="https://yaquevoy.com/terminos-condiciones.html"
                            target={"_blank"}
                          >
                            términos y condiciones
                          </a>
                          .
                        </Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.terms_accepted}
                        </Form.Control.Feedback>
                      </FormCheck>
                    </Form.Group>

                    {status === "KO" && (
                      <Alert
                        variant="danger"
                        // onClose={() => setShow(false)}
                        dismissible
                      >
                        <Alert.Heading>Ops! Ocurrió un error.</Alert.Heading>
                        <p>Intenta más tarde o comunícate con nosotros.</p>
                      </Alert>
                    )}

                    {status === "OK" && (
                      <Alert
                        variant="success"
                        // onClose={() => setShow(false)}
                        dismissible
                      >
                        <Alert.Heading>Gracias por registrarte!</Alert.Heading>
                        <p>En breve nos comunicaremos contigo.</p>
                      </Alert>
                    )}

                    <div className="form-group">
                      {loading ? (
                        <img
                          src={loadingImg}
                          className="loading"
                          alt="loading"
                        />
                      ) : (
                        <Button
                          type="submit"
                          className="btn btn-primary"
                          id="regidter"
                        >
                          Registrarme
                        </Button>
                      )}
                    </div>
                  </Form>
                </div>
                <div className="card-footer px-lg-5 py-lg-4">
                  <div className="text-sm text-muted">
                    {/* Already have an account?{" "}
                    <a onClick={this.showlogin}>Login</a>. */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-5 ms-xl-auto px-lg-4 text-center text-primary">
              {/* <img
                className="img-fluid mb-4"
                width="400"
                src={registerVector}
                alt=""
                style={{ transform: "rotate(10deg)" }}
              /> */}
              <ReactPlayer
                loop="true"
                playing="true"
                width="400"
                url="https://fb.watch/k5hEJxkcgg/"
                // light={<img src={previewVideo} alt="Thumbnail" />}
              />
              <h1 className="mb-4">
                {/* Therichpost.com <br className="d-none d-lg-inline" />
                free code snippets. */}
                Registro
              </h1>
              <p className="lead text-muted">
                Por cualquier duda comunícate por WhatsApp al{" "}
                <a href="https://wa.me/59892000054">092000054</a>.
              </p>
              <p>&nbsp;</p>
              <h3 className="mb-4">Descarga el instructivo para conductores</h3>
              <a href="https://yaquevoy.com/instructivo-conductores.pdf">
                <img
                  className="img-fluid mb-4"
                  width="40"
                  src={downloadInstructions}
                  alt="Instructivo conductores"
                />
              </a>
              <p>&nbsp;</p>
              <p className="lead text-muted">Descarga la app en:</p>
              <a href="https://play.google.com/store/apps/details?id=com.yaquevoy.app">
                <img
                  className="img-fluid mb-4"
                  width="170"
                  src={googlePlayLogo}
                  alt="Google Play logo"
                />
              </a>
              <a href="https://apps.apple.com/us/app/yaquevoy/id6446854366">
                <img
                  className="img-fluid mb-4"
                  width="154"
                  src={appStoreLogo}
                  alt="App Store logo"
                />
              </a>
            </div>
          </div>
          <div className="row align-items-center justify-content-center align-self-center mt-4">
            <img
              className="img-fluid"
              src={logoAnde}
              alt="logo ande"
              style={{ width: "290px" }}
            />
            <img
              className="img-fluid"
              src={logoAnii}
              alt="logo anii"
              style={{ width: "290px" }}
            />
            <img
              className="img-fluid"
              src={logoThales}
              alt="logo thaleslabs"
              style={{ width: "290px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
